import InfoCard from "components/InfoCard/InfoCard";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import AddressForm from "../../../../../components/AddressForm";
import { AddressFormErrors } from "../../../../../components/AddressForm/AddressForm";
import { ActiveStepProps } from "../../../../../components/containers/Step/types";
import MapSelector from "../../../../../components/forms/MapAddressSelector/MapSelector";
import WindowSelector from "../../../../../components/forms/WindowSelector";
import { LogisticWindow } from "../../../../../components/forms/WindowSelector/types";
import LuggageIcon from "../../../../../components/icons/LuggageIcon";
import MinusIcon from "../../../../../components/icons/MinusIcon";
import PlusIcon from "../../../../../components/icons/PlusIcon";
import Button from "../../../../../components/inputs/buttons/Button";
import IntegerInput from "../../../../../components/inputs/IntegerInput";
import ValidationError from "../../../../../components/ValidationError";
import { useOptions, useProperties } from "../../../../../hooks";
import useTranslations from "../../../../../hooks/useTranslations";
import { Coordinates, Location, PickupType } from "../../../../../store/booking/bookingTypes";
import { BookingState } from "../../../../../store/booking/types/store";
import { Flow } from "../../../../../store/channel/channel.types";
import { AirportDirection } from "../../../../../store/flight/types/enums";
import { FlightState } from "../../../../../store/flight/types/store";
import { LocationType } from "../../../../../store/portal/portalTypes";
import sharedStyles from "../../../../../styles/Shared.module.scss";
import Utils, { OSMLocation } from "../../../../../utils/Utils";
import { LocationStepErrors } from "../LocationStep";
import styles from "../LocationStep.module.scss";
import Modal from "components/modals/Modal";
import { ColourTypes } from "components/inputs/buttons/Button/Button";
import { useAppSelector } from "store";

declare function UpdateCallback(data: { luggage: number, location: Location, window: LogisticWindow }): void;
declare function UpdateCallback(data: { location: Location, window: LogisticWindow }): void;
declare function UpdateCallback(data: { location: Location }): void;
declare function UpdateCallback(data: { luggage: number }): void;
declare function UpdateCallback(data: { luggage?: number, window?: LogisticWindow, location?: Location }): void;

interface Props extends ActiveStepProps {
  loading: boolean;
  onUpdate: typeof UpdateCallback;
  onSubmit: () => void;
  data: {
    error: LocationStepErrors;
    flight: Required<FlightState>;
    booking: BookingState;
    direction: Required<AirportDirection>;
    flow?: Flow;
    hubs?: Required<Location>[];
  }
}

interface Checkboxes {
  termsAndConditions: boolean;
  dataProcessing: boolean;
}

const LocationStepActive: React.FC<Props> = (
  {
    onSubmit,
    onUpdate,
    loading,
    data,
  }
) => {
  /**
   * This component is used to collect and validate location and time windows for a given booking.
   *
   * If the information is valid, we trigger the onUpdate callback with the relative information in order
   * to ensure that a booking is created and updated whenever a change is done by the customer.
   *
   * Once the information is validated and the submit button is clicked, the information is passed back to
   * the LocationStep via the onSubmit callback in order to create an Order.
   */

  const { properties } = useProperties();
  const { translation } = useTranslations();
  const { options } = useOptions();

  const getInitialWindow = useCallback(() => {
    if (data.booking.details?.meta?.timeslot === undefined) return

    const date = new Date(data.booking.details.meta.timeslot.formatted).getTime()
    const day = options[date];
    if (day)
      return day.find(slot => slot.label === data.booking.details?.meta?.timeslot?.timeLabel);
  }, [options, data.booking.details?.meta?.timeslot])

  const [luggage, setLuggage] = useState<number>(
    (data.flight.passengers && data.flow === Flow.Airport)
      ? data.flight.passengers.reduce((acc, obj) => acc + obj.luggage || 0, 0)
      : data.booking.details?.assignments[0]?.luggage_count || 1
  );
  const [location, setLocation] = useState<Location | undefined>(data.booking.meeting?.location);
  const [window, setWindow] = useState<LogisticWindow | undefined>(getInitialWindow());
  const [mapCenter, setMapCenter] = useState<Coordinates>();
  const [errors, setErrors] = useState<AddressFormErrors>();
  const [type, setType] = useState<PickupType>(location?.type === LocationType.bagpoint ? PickupType.Hub : PickupType.Address);
  const [isGeocoding, setIsGeocoding] = useState<boolean>(false);
  const [addressModalOpen, setAddressModalOpen] = useState<boolean>(false);
  const [addressOptions, setAddressOptions] = useState<any>([]);

  const city = useMemo(() => data.flight.journey[data.direction].schedule.airport.city, [data.flight, data.direction])

	const searchBar = useAppSelector(state => state.channel.properties.switches.searchBar)

  const [checkbox, setCheckbox] = useState<Checkboxes>({
    termsAndConditions: false,
    dataProcessing: data.direction === AirportDirection.Arrival,
  });

  const allCheckboxesSigned = useMemo(() => Utils.object
    .entries(checkbox)
    .filter(([, value],) => !value)
    .length === 0, [checkbox])

  const isReadyToCreate: boolean = useMemo(() => {
    /**
     * Used to validate if the BOOKING is ready to be created.
     */

    if (errors === undefined) return false;

		if(!window) return false;

    return !loading && !Object.keys(errors).length
  }, [errors, window, loading]);

  const isReadyToOrder: boolean = useMemo(() => {
    /**
     * Used to validate if the ORDER is ready to be created.
     */

    if (errors === undefined) return false;

    return isReadyToCreate
      && !!data.booking.details?.assignments
      && allCheckboxesSigned
      && !Object.keys(errors).length
  }, [allCheckboxesSigned, isReadyToCreate, data.booking, errors])

  const isReadyForWindowSelection = useMemo(() => {
    /**
     * Allow the customer to select a time window once a location has
     * been selected.
     */

    return !loading && location !== undefined;
  }, [location, loading])

  const onError = useCallback((formErrors: { [key: string]: string }) => {
    /**
     * Merge the form errors under a common form object
     */
    setErrors(formErrors)
  }, [])

  const handleSubmit = useCallback(() => {
    /**
     * Listen for any action on the next button and if the form is valid,
     * create an order and move to the next step
     */

    isReadyToOrder && onSubmit()
  }, [isReadyToOrder, onSubmit]);

  const onWindowSelect = useCallback((window: LogisticWindow) => {
    /**
     * Save the provided window information and update the booking if said booking
     * has already been created.
     */

    setWindow(window);
    const updateLocation = location || data.booking?.meeting?.location;

    if (updateLocation && window) onUpdate({ luggage, location: updateLocation, window })
  }, [location, luggage, data, onUpdate])

  const onFormLocationChange = useCallback(async (addressData: any) => {
    /**
     * Save the provided location information and update the booking if said booking
     * has already been created.
     */

    setIsGeocoding(true);

    try {
      const response: OSMLocation[] = await Utils.geo.getOSMGeocode(addressData);

      if (response.length === 1) {
        const separate = response[0].address.house_number.split(/(\d+)/g).filter(item => item !== "");

				console.log(response)
        const number = separate.shift() || "";
        const addition = separate.join("").replace("-", "");
        const location = Utils.geo.toLocationOSM(response[0])

        const formatted = {
          ...location,
          address: {
            ...location.address,
            addition,
            number
          }
        }

        onUpdate({location: formatted})
        setLocation(formatted);
        setWindow(undefined)
      }

      if (Array.isArray(response) && response.length > 1) {
        setAddressModalOpen(true);
        setAddressOptions(response);
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsGeocoding(false);
    }
  }, [onUpdate])

	const onMapLocationChange = useCallback((location?: Location) => {
		setLocation(location);
		setWindow(undefined);
	}, [])

  const onLuggageChange = useCallback((luggage: number) => {
    /**
     * Save the luggage count information and update the booking if said booking
     * has already been created.
     */

    setLuggage(luggage);


    const updateLocation = location || data.booking?.meeting?.location;
    if (updateLocation && window) onUpdate({ luggage, location: updateLocation, window });
  }, [location, data, window, onUpdate])

  const onCheckboxChange = useCallback((checkbox: keyof Checkboxes) => {
    /**
     * Handle checkbox changes.
     */

    setCheckbox((checkboxes) => {
      return {
        ...checkboxes,
        [checkbox]: !checkboxes[checkbox]
      }
    })
  }, [])

  useLayoutEffect(() => {
    /**
     * Center the map on the city tied to the given airport.
     */

    const {latitude, longitude} = data.flight.journey[data.direction].schedule.airport;

    (async () => {
      try {
        const response = await Utils.geo.getOSMGeocodeFromCoordinates(latitude, longitude);
        const {lat, lon} = response?.data || {};

        if (lat && lon) {
          setMapCenter({
            latitude: parseFloat(lat),
            longitude: parseFloat(lon)
          });
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [data.direction, data.flight.journey]);

  useEffect(() => {
    if(Object.keys(options).length === 0)
      onError({
        pickup: translation.get('validation:location:address_not_serviceable')
      })
  }, [location, translation, options, onError])

  return (
    <><>
      <h2 className={styles.heading}>
        {translation.get(data.flow === Flow.Airport
          ? "location:heading:airport"
          : "location:heading:city"
        )}
      </h2>

			<Modal
        contentContainerClassName={styles.modalContentWrapper}
        className={styles.addressModal}
        hide={() => setAddressModalOpen(false)}
        modalContent={
          <div className={styles.modalContent}>
            {addressOptions.map((address: OSMLocation) => {
              return <Button
                key={address.place_id}
                color={ColourTypes.primary}
                className={styles.addressButton}
                text={address.display_name}
                onClick={() => {
                  if (!address.address.house_number) {
                    setAddressModalOpen(false);
                    const location = Utils.geo.toLocationOSM(address)
                    onUpdate({location})
                    setLocation(location)
                    return;
                  }

                  const separate = address.address.house_number.split(/(\d+)/g).filter(item => item !== "");
                  const number = separate.shift() || "";
                  const addition = separate.join("").replace("-", "");
                  const location = Utils.geo.toLocationOSM(address)

                  const formatted = {
                    ...location,
                    address: {
                      ...location.address,
                      addition,
                      number
                    }
                  }

                  setLocation(formatted)
                  onUpdate({location: formatted})
                  setAddressModalOpen(false);
                }}/>
            })}
          </div>
        }
        isShown={addressModalOpen}
        headerText=""
      />

      <div className={styles["map-container"]}>
        {mapCenter &&
          <MapSelector
						formLoading={isGeocoding}
						searchBar={searchBar}
						clickable
            onChange={onMapLocationChange}
            location={location}
            allowTypeSelection={data.flow === Flow.Airport && properties.switches.location.display}
            type={type}
            setType={setType}
            center={mapCenter}
            zoom={11}
            hubs={data.hubs || []}
            onError={() => {
            }}
          />
        }
      </div>

      <AnimatePresence>
        {data.error.location && (
          <motion.div
            className={styles.errorMessage}
            initial={{ height: '60px', opacity: 0, y: '300px', x: '-50%' }}
            animate={{ height: 'auto', opacity: 1, y: '0', x: '-50%' }}
            exit={{ height: '60px', opacity: 0, y: '300px', x: '-50%' }}
            transition={{ ease: "easeInOut", duration: 0.4, overflow: 'hidden' }}
          >
            <div className={sharedStyles.errorWithTitle}>
              <div>
                {translation.get("error:title")}
              </div>
              <ValidationError error={data.error.location} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className={styles.wrapper}>
        <AddressForm
          disabled={type === PickupType.Hub}
          className={styles.flexContainer}
          onUpdate={onFormLocationChange}
          location={location}
          country={data.flight.journey[data.direction].schedule.airport.country}
          onError={onError} />

        <div className={`${styles.flexContainer}`}>
          <WindowSelector
            key={`${Object.keys(options)[0]} ${city}`}
            disabled={!isReadyForWindowSelection}
            onUpdate={onWindowSelect}
            options={options}
            dateLabel={translation.get(data.flow === Flow.Airport
              ? "location:label_date:airport"
              : "location:label_date:city")}
            timezone={data.flight.journey[data.direction].schedule.airport.timezone}
            window={window}
          />
          {errors?.pickup && <InfoCard type="error" title="Error" subtitle={errors.pickup} className={styles.pickupError} />}
          
          {(data.flow === Flow.City || !data.flight.passengers) && <div className={styles["luggage-count"]}>
            <div className={styles["luggage-count-input-container"]}>
              <IntegerInput
                label={data.direction === AirportDirection.Departure
                  ? translation.get("location:label_luggage:airport")
                  : translation.get("location:label_luggage:city")}
                icon={LuggageIcon}
                name="luggageCount"
                value={luggage}
                min={1}
                max={18}
                disabled={!isReadyForWindowSelection}
                onChange={onLuggageChange}
                addIcon={PlusIcon}
                subtractIcon={MinusIcon} />
            </div>
          </div>}
        </div>
      </div>
      <div className={styles["submit-button-wrapper"]}>
        <div className={sharedStyles.checkbox}>
          <input
            data-cy="checkbox-terms"
            type="checkbox"
            id="userAgreement"
            onChange={() => {}}
            checked={checkbox.termsAndConditions}
            onClick={() => onCheckboxChange('termsAndConditions')} />
          <label htmlFor="userAgreement">
            <p style={{ margin: 0 }}
              dangerouslySetInnerHTML={{ __html: translation.get("location:first_policy_checkbox", { url: 'https://www.bagpoint.com/terms-conditions/' }) }} />
          </label>
          {data.direction === AirportDirection.Departure && <>
            <input
              data-cy="checkbox-data"
              type="checkbox"
              id="dataProcessingAgreement"
              checked={checkbox.dataProcessing}
              onChange={() => {}}
              onClick={() => onCheckboxChange('dataProcessing')} />
            <label htmlFor="dataProcessingAgreement">
              {translation.get("location:second_policy_checkbox")}
            </label>
          </>}
        </div>
        <Button
          type="submit"
          data-cy="location-submit"
          id="location-submit"
          text={translation.get("button:next")}
          onClick={handleSubmit}
          disabled={!(isReadyToCreate
            && !!data.booking.details?.assignments
            && allCheckboxesSigned)} />
      </div>
    </>
    </>
  );
};

export default LocationStepActive;
